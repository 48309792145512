import { IconButton, IconButtonProps, TXProp } from '@air/zephyr';
import classNames from 'classnames';
import { memo } from 'react';

interface DarkIconButtonProps
  extends Pick<IconButtonProps, 'icon' | 'onClick' | 'className' | 'size' | 'children' | 'onMouseEnter'> {
  isLight?: boolean;
  testId?: string;
  tx?: TXProp;
  disabled?: boolean;
}

/** @deprecated - use IconButton from @air/primitives */
export const DarkIconButton = memo(
  ({ icon, onClick, className, size, tx, children, isLight, disabled, onMouseEnter, testId }: DarkIconButtonProps) => {
    return (
      <IconButton
        disabled={disabled}
        onMouseEnter={onMouseEnter}
        icon={icon}
        onClick={disabled ? undefined : onClick}
        variant="button-unstyled"
        data-testid={testId}
        className={classNames(className, { isDisabled: disabled })}
        size={size}
        tx={{
          ...(isLight
            ? {}
            : {
                color: 'pigeon100',
                ['&:hover']: {
                  backgroundColor: 'pigeon700',
                  color: 'pigeon050',
                },
                ['&.isDisabled']: {
                  color: 'pigeon400',
                },
              }),
          ...tx,
        }}
      >
        {children}
      </IconButton>
    );
  },
);

DarkIconButton.displayName = 'DarkIconButton';
