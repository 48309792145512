import { Avatar } from '@air/primitive-avatar';
import { formatFullName, formatInitials } from '@air/utilities';
import { MentionsInput } from 'react-mentions';
import styled from 'styled-components';

import { MentionsData } from '~/components/Discussions/DiscussionForm';
import { DISCUSSION_COMMENT_AVATAR, MENTION_SUGGESTION } from '~/constants/testIDs';
import { preventDefaultMouseEvent } from '~/utils/ClickUtils';
import { getAvatarImageSrc } from '~/utils/getAvatarImageSrc';

export const inputStyles = {
  control: {
    fontSize: '0.875rem',
  },
  suggestions: {
    marginTop: 0,
    borderRadius: '4px',
    width: '280px',
    transform: 'translateX(0)',
    bottom: 'auto',
    top: 'auto',
    list: {
      border: `1px solid ${({ theme }: any) => theme.colors.pigeon100}`,
      boxSizing: 'border-box',
      boxShadow: '0 0 4px rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
      background: `linear-gradient(0deg, ${({ theme }: any) => theme.colors.white}, ${({ theme }: any) =>
        theme.colors.white}), ${({ theme }: any) => theme.colors.white}`,
      width: '100%',
      maxHeight: '296px',
      overflowY: 'auto',
    },
  },
};

export const MentionsContainer = styled(MentionsInput)`
  width: 100%;
  max-height: 150px;
  overflow-y: initial;
  overflow-x: hidden;
  text-overflow: ellipsis;

  > div {
    position: relative;

    > div {
      padding: 12px 12px 0;
      border: 1px solid transparent;
    }
  }

  textarea {
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    resize: none;
    display: block;
    border: 1px solid transparent;
    padding: 12px 12px 0px;

    &:focus {
      outline: none;
      border-color: transparent;
    }

    &:focus-visible {
      color: 'none';
      border: 'none';
      outline: 'none';
    }
  }
`;

export const MENTION_SUGGESTION_ID = 'mention-suggestion';

export const Suggestion = ({ avatar, firstName, lastName, id: userId }: MentionsData) => (
  <div
    className="flex cursor-pointer items-center px-4 py-3 hover:bg-pigeon-50"
    data-testid={MENTION_SUGGESTION}
    id={MENTION_SUGGESTION_ID}
    onMouseDown={preventDefaultMouseEvent}
  >
    <Avatar
      alt={`${firstName} ${lastName} avatar`}
      data-testid={DISCUSSION_COMMENT_AVATAR}
      size={24}
      src={getAvatarImageSrc(avatar, 24)}
      text={formatInitials(firstName, lastName)}
      colorSeed={userId}
    />

    <div
      data-testid="MENTION_SUGGESTION_USERNAME"
      className="ml-3 w-[calc(100%-36px)] truncate text-14 text-pigeon-700"
    >
      {formatFullName(firstName, lastName)}
    </div>
  </div>
);
